import {
  Grid,
  InputAdornment,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  Badge,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import CommonDrawer from "../../components/SideDrawer";
import CompanyDetails from "../request/CompanyDetails";
import ConfirmDialog from "../../components/ConfirmDialog";
import Controls from "../../components/controls/Controls";
import ViewFaults from "../../assets/images/svg/View Faults V2.svg";
import EditIcon from "@mui/icons-material/Edit";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import InfoIcon from "@mui/icons-material/Info";
import EvStationOutlinedIcon from "@mui/icons-material/EvStationOutlined";
import Loader from "../../components/Loader";
import OpsBreadcrumb from "../../components/NewBreadcrumbs";
import PageHeader from "../../components/PageHeader";
import PageMainContent from "../../components/PageMainContent";
import { PrivilegedComponent } from "../../utils/PrivilegedComponent";
import SearchIcon from "@mui/icons-material/Search";
import SyncIcon from "@mui/icons-material/Sync";
import ToastMessage from "../../components/ToastMessage";
import TuneIcon from "@mui/icons-material/Tune";
import apiDepots from "../../services/api/Depots";
import { getBillingDepotsPlanDetails } from "../billing/BillingServices";
import { getDepotDetailsByCompanyId } from "../services/InternalApisService";
import { isEqual } from "lodash";
import { titleLabels } from "../../constants/TitleLabels";
import useTable from "../../components/UseTable";
import { useHistory } from "react-router-dom";
import { routePaths } from "../../constants/RoutePaths";
import NewCommonFilterDrawer from "../../components/NewSideFIlterDrawer";
import NewDepotsFilter from "./NewDepotsFilter";
import Popup from "../../components/Popup";
import ExportCSV from "../../components/ExportCSV";
import {
  allCountryCodeForFilter,
  allDepotStatus,
  allDepotTypesForFilter,
  minaEnrolledFilter,
  isAdvenirEnrolledDepotFilter,
  isWorkplaceChargingDepotFilter,
  sendGetConfigurationFilter,
  sharedChargingFilter,
  cdrFlagFilter,
  simulatedFlagFilter,
  enableCircuitsFilter,
  publicCdrFilter,
  allHomeChargingFilter,
  rfidCapableFilter,
  cloudProviderFilter,
} from "../../constants/FilterConstants";
import { usersActionCreator } from "../../redux-state/actions";
import { getActiveDepots } from "./companiesServices";
import "./companies.scss";
import ComponentLoader from "../../components/ComponentLoader";

const AllBreadcrumbsLinks = [
  {
    link: "/companies",
    title: "Companies",
  },
];

const headCells = (componentPrivileges) => {
  const hc = [
    { id: "depotName", label: "Depot Name" },
    { id: "status", label: "Status" },
  ];
  if (componentPrivileges) {
    if (
      componentPrivileges.includes("Edit Depot Preferences") ||
      componentPrivileges.includes("Update Data Sync")
    ) {
      hc.push({ disableSorting: true, id: "action", label: "Action" });
    }
  }

  return hc;
};

const Rows = (props) => {
  const {
    item,
    toggleDrawer,
    handleConfirmUpdateDataSync,
    componentPrivileges,
  } = props;
  const history = useHistory();
  return (
    <>
      <TableRow hover={true}>
        <TableCell>
          <Tooltip
            title={
              item.expiryDate
                ? (new Date(item.expiryDate) - new Date()) /
                    (1000 * 3600 * 24) <
                  7
                  ? (new Date(item.expiryDate) - new Date()) /
                      (1000 * 3600 * 24) <
                    0
                    ? "Expired on " + item.expiryDate
                    : "Expires on " + item.expiryDate
                  : ""
                : ""
            }
            placement="bottom-start"
          >
            <a
              id="companiesDepotName"
              className={`${
                item.expiryDate
                  ? (new Date(item.expiryDate) - new Date()) /
                      (1000 * 3600 * 24) <
                    7
                    ? "expiring"
                    : null
                  : null
              } ${"table-onclick-col"} `}
              onClick={() => {
                toggleDrawer(true, "Depots Details", item);
              }}
            >
              {item.depotName + " "}
              {item.isHomeChargingDepot ? (
                <HomeRoundedIcon
                  fontSize="small"
                  title="Home Charging Depot"
                  className="homeChargingIcon"
                ></HomeRoundedIcon>
              ) : (
                ""
              )}
            </a>
          </Tooltip>
        </TableCell>
        <TableCell>
          <span
            id="capitalizeText"
            className={
              item.status === "running"
                ? "running"
                : item.status === "provisioning"
                ? "provisioning"
                : "null"
            }
          >
            {item.status || titleLabels.NOT_AVAILABLE}
          </span>
        </TableCell>
        <TableCell>
          <Controls.ActionButton
            color="primary"
            disabled={item.status !== "running"}
            onClick={() =>
              history.push(
                `${routePaths.OPENFAULTS}?depotId=${item?.depotId}`,
                { from: "CompanyDepotDetails" }
              )
            }
          >
            <Tooltip title="View Faults">
              <img color="primary" src={ViewFaults} alt="View Faults" />
            </Tooltip>
          </Controls.ActionButton>
          <PrivilegedComponent permission="Update Data Sync" module="companies">
            <Controls.ActionButton
              color="primary"
              disabled={item.status !== "running"}
              onClick={() => handleConfirmUpdateDataSync({ ...item })}
            >
              <Tooltip title="Depot Synchronise">
                <SyncIcon fontSize="small" />
              </Tooltip>
            </Controls.ActionButton>
          </PrivilegedComponent>
          <PrivilegedComponent
            permission="Edit Depot Preferences"
            module="companies"
          >
            <Tooltip title={"Edit depot preferences"}>
              <span>
                <Controls.ActionButton
                  color="primary"
                  disabled={item.status !== "running"}
                  onClick={() =>
                    toggleDrawer(true, "Depots Details", {
                      ...item,
                      isEdit: true,
                    })
                  }
                >
                  <EditIcon fontSize="small" />
                </Controls.ActionButton>
              </span>
            </Tooltip>
          </PrivilegedComponent>
          <Tooltip title={"View Chargers"}>
            <span>
              <Controls.ActionButton
                color="primary"
                onClick={() =>
                  history.push(
                    `${routePaths.CHARGERFIRMWAREDETAILS}?depotID=${item?.depotId}`,
                    { from: "CompanyDepotDetails" }
                  )
                }
              >
                <EvStationOutlinedIcon fontSize="small" />
              </Controls.ActionButton>
            </span>
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  );
};

const ToolbarMemoised = React.memo(
  ({
    handleSearch,
    className,
    filterToggleDrawer,
    isFiltered,
    count,
    activeDepotCount,
    provisioningDepotCount,
    getCount,
    isLoading,
    data,
  }) => {
    return (
      <Toolbar className="table_toolbar">
        <Controls.Input
          label={"Search Depot"}
          className={className}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        <Typography className="companiesDepotCount">Depots: {count}</Typography>
        <Tooltip
          title={
            "Active Depots: " +
            activeDepotCount +
            ", Provisioning Depots: " +
            provisioningDepotCount
          }
        >
          <InfoIcon color="primary" />
        </Tooltip>
        <Grid item sm />

        <Grid>
          <ComponentLoader isLoading={isLoading}>
            <Controls.Button
              text="Export CSV"
              variant="outlined"
              id="companiesExportCSV"
              disabled={data?.length === 0}
              onClick={() => {
                getCount();
              }}
            />
          </ComponentLoader>
        </Grid>

        <Badge
          color="primary"
          variant="dot"
          invisible={isFiltered ? false : true}
        >
          <Controls.Button
            text="Filter"
            variant="outlined"
            className="filter"
            startIcon={
              <TuneIcon
                style={{ color: isFiltered === true ? "#2770D8" : "#A3B6C7" }}
              />
            }
            onClick={() => {
              filterToggleDrawer(true, "FILTER", {});
            }}
            style={{
              border:
                isFiltered === true ? "1px solid #2770D8" : "1px solid #A3B6C7",
            }}
          />
        </Badge>
      </Toolbar>
    );
  }
);

const RowsMemoised = React.memo((props) => {
  return <Rows {...props} />;
});

const TableBodyMemoised = React.memo(
  ({
    data,
    toggleDrawer,
    setLoading,
    setToast,
    handleConfirmUpdateDataSync,
    componentPrivileges,
  }) => {
    return (
      <TableBody>
        {data.map((item, index) => (
          <RowsMemoised
            item={item}
            key={`${item.email}_${index}`}
            toggleDrawer={toggleDrawer}
            setToast={setToast}
            componentPrivileges={componentPrivileges}
            setLoading={setLoading}
            handleConfirmUpdateDataSync={() =>
              handleConfirmUpdateDataSync(item)
            }
          />
        ))}
      </TableBody>
    );
  },
  function (prevProps, nextProps) {
    if (isEqual(prevProps.data, nextProps.data)) {
      return true;
    }
    return false;
  }
);

const CompanyDepotDetails = (props) => {
  const dispatch = useDispatch();
  const { saveAllUsersGlobal } = bindActionCreators(
    usersActionCreator,
    dispatch
  );
  const { allUsers, isUsersLoaded } = useSelector(
    (state) => state.allUsersGlobal
  );
  const componentPrivilege = useSelector(
    (state) => state.user.componentPrivilege.companies
  );

  const {
    match: {
      params: { id, depotId },
    },
  } = props;

  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    type: "primary",
  });

  const [DrawerOC, setDrawerOC] = React.useState({
    isOpen: false,
    title: "",
    data: {},
  });
  const [FilterDrawerOC, setFilterDrawerOC] = React.useState({
    isOpen: false,
    title: "",
    data: {},
  });
  const [toast, setToast] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const initialFilterValues = {
    countryCode: null,
    timeZone: null,
  };

  const [allDepotsType, setAllDepotsType] = React.useState(
    JSON.parse(JSON.stringify(allDepotTypesForFilter))
  );

  const [allCdrFlag, setAllCdrFlag] = React.useState(
    JSON.parse(JSON.stringify(cdrFlagFilter))
  );

  const [allSimulatedFlag, setAllSimulatedFlag] = React.useState(
    JSON.parse(JSON.stringify(simulatedFlagFilter))
  );

  const [allSharedChargingFlag, setAllSharedChargingFlag] = React.useState(
    JSON.parse(JSON.stringify(sharedChargingFilter))
  );

  const [allEnableCircuitsFlag, setAllEnableCircuitsFlag] = React.useState(
    JSON.parse(JSON.stringify(enableCircuitsFilter))
  );
  const [publishCDRsToCCDR, setPublishCDRsToCCDR] = React.useState(
    JSON.parse(JSON.stringify(publicCdrFilter))
  );

  const [cloudProvider, setCloudProvider] = React.useState(
    JSON.parse(JSON.stringify(cloudProviderFilter))
  );

  const [depotStatus, setDepotStatus] = React.useState(
    JSON.parse(JSON.stringify(allDepotStatus))
  );
  const [openPopup, setOpenPopup] = React.useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });
  const [depotsData, setDepotsData] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [filterFn, setFilterFn] = React.useState({ fn: (items) => items });
  const [requests, setRequests] = React.useState([]);
  const [requestsData, setRequestsData] = React.useState([]);
  const [TableContainer, setTableContainer] = React.useState("table");
  const [TableHead, setTableHead] = React.useState("thead");
  const [isLoading, setLoading] = React.useState(false);
  const [TablePagination, setTablePagination] = React.useState(() => <></>);
  const [filterData, setFilterData] = React.useState(initialFilterValues);
  const [recordsAfterPagingAndSorting, setRecordsAfterPagingAndSorting] =
    React.useState([]);
  const [companyInfo, setCompanyInfo] = useState({});
  const [depotsForFilter, setDepotsForFilter] = useState([]);
  const [tableHeadCells, setTableheadCells] = React.useState(
    headCells(componentPrivilege)
  );
  const [isFiltered, setIsFiltered] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const result = useTable(requests, tableHeadCells, filterFn, true);
  const [activeDepotCount, setActiveDepotCount] = React.useState(0);
  const [provisioningDepotCount, setProvisioningDepotCount] = React.useState(0);
  const [countryCode, setCountryCode] = React.useState(
    JSON.parse(JSON.stringify(allCountryCodeForFilter))
  );
  const [timeZones, setTimeZones] = React.useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = React.useState([]);

  const [minaEnrolledFlag, setMinaEnrolledFlag] = React.useState(
    JSON.parse(JSON.stringify(minaEnrolledFilter))
  );
  const [isAdvenirEnrolledDepotFlag, setIsAdvenirEnrolledDepotFlag] =
    React.useState(JSON.parse(JSON.stringify(isAdvenirEnrolledDepotFilter)));

  const [isWorkplaceChargingDepotFlag, setIsWorkplaceChargingDepotFlag] =
    React.useState(JSON.parse(JSON.stringify(isWorkplaceChargingDepotFilter)));

  const [sendGetConfigurationFlag, setSendGetConfigurationFlag] =
    React.useState(JSON.parse(JSON.stringify(sendGetConfigurationFilter)));

  const [allHomeChargingFlag, setAllHomeChargingFlag] = React.useState(
    JSON.parse(JSON.stringify(allHomeChargingFilter))
  );
  const [isRFIDCapable, setIsRFIDCapable] = React.useState(
    JSON.parse(JSON.stringify(rfidCapableFilter))
  );
  const [headers, setHeaders] = React.useState([
    { name: "Depot Name", checked: false, key: "depotName" },
    { name: "Depot Id", checked: false, key: "depotId" },
    { name: "Company Name", checked: false, key: "companyName" },
    { name: "Company Id", checked: false, key: "companyId" },
    { name: "Status", checked: false, key: "status" },
    { name: "isCDR", checked: false, key: "isCDR" },
    { name: "isSimulated", checked: false, key: "isSimulated" },
    { name: "isHomeChargingDepot", checked: false, key: "isHomeChargingDepot" },
    { name: "publishCDRsToCCDR", checked: false, key: "publishCDRsToCCDR" },
    {
      name: "isMinaEnrolledDepot",
      checked: false,
      key: "isMinaEnrolledDepot",
    },
    {
      name: "isAdvenirEnrolledDepot",
      checked: false,
      key: "isAdvenirEnrolledDepot",
    },
    {
      name: "isWorkplaceChargingDepot",
      checked: false,
      key: "isWorkplaceChargingDepot",
    },
    {
      name: "sendGetConfiguration",
      checked: false,
      key: "sendGetConfiguration",
    },
    { name: "isRFIDDepot", checked: false, key: "isRFIDDepot" },
    {
      name: "isSharedChargingDepot",
      checked: false,
      key: "isSharedChargingDepot",
    },
    { name: "isCircuitEnabled", checked: false, key: "isCircuitEnabled" },
    { name: "ZuoraId", checked: false, key: "zuoraId" },
    { name: "Country Code", checked: false, key: "countryCode" },
    { name: "State", checked: false, key: "state" },
    { name: "City", checked: false, key: "city" },
    { name: "Street Address", checked: false, key: "streetAddress" },
    { name: "Country", checked: false, key: "country" },
    {
      name: "Fully Automated Mode Count",
      checked: false,
      key: "fullyAutomatedChargingMode",
    },
    {
      name: "Supervised Mode Count",
      checked: false,
      key: "simpleChargingMode",
    },
    {
      name: "Unmanaged Mode Count",
      checked: false,
      key: "unmanagedChargingMode",
    },
    { name: "Charger Count", checked: false, key: "chargerCount" },
    { name: "Port Count", checked: false, key: "portCount" },
  ]);
  useEffect(() => {
    getDepotInfo();
  }, []);

  useEffect(() => {
    setTableContainer(() => result.tableContainer);
  }, [result.tableContainer]);
  useEffect(() => {
    setTableHead(() => result.tableHead);
  }, [result.tableHead]);
  useEffect(() => {
    setTablePagination(result.tablePagination);
  }, [result.tablePagination]);
  useEffect(() => {
    if (depotId && result?.recordsAfterPagingAndSorting) {
      // depotId path param will be present when redirected from map component on home page
      const matchedDepot = result.recordsAfterPagingAndSorting?.find(
        (row) => row.depotId === depotId
      );
      if (matchedDepot) toggleDrawer(true, "Depots Details", matchedDepot);
    }
    setRecordsAfterPagingAndSorting(result.recordsAfterPagingAndSorting);
  }, [result.recordsAfterPagingAndSorting]);

  const handlePopup = React.useCallback(
    (parms) => {
      setOpenPopup(parms);
    },
    [openPopup]
  );

  // Export CSV data constructor
  React.useEffect(() => {
    const depotsList = requests.map((element) => {
      return {
        ...element,
        ...companyInfo,
      };
    });
    const depotsDetailsList = depotsList.map((depotInfo) => {
      depotInfo.chargerCount =
        depotInfo.chargerCount === undefined ? 0 : depotInfo.chargerCount;
      depotInfo.portCount =
        depotInfo.portCount === undefined ? 0 : depotInfo.portCount;
      return {
        ...depotInfo.depotDetails.depotInfo.address,
        ...depotInfo,
      };
    });
    setDepotsData(depotsDetailsList);
  }, [requests, companyInfo]);

  // Export CSV ALL headers
  const allColumnsHeaders = [
    { key: "depotName", label: "Depot Name" },
    { key: "depotId", label: "Depot ID" },
    {
      key: "companyName",
      label: "Company Name",
    },
    {
      key: "companyId",
      label: "Company Id",
    },
    {
      key: "status",
      label: "Status",
    },
    { key: "isCDR", label: "isCDR" },
    { key: "isSimulated", label: "isSimulated" },
    { key: "isHomeChargingDepot", label: "isHomeChargingDepot" },
    { key: "isMinaEnrolledDepot", label: "isMinaEnrolledDepot" },
    { key: "isAdvenirEnrolledDepot", label: "isAdvenirEnrolledDepot" },
    { key: "isWorkplaceChargingDepot", label: "isWorkplaceChargingDepot" },
    { key: "sendGetConfiguration", label: "sendGetConfiguration" },
    { key: "isRFIDDepot", label: "isRFIDDepot" },
    { key: "isSharedChargingDepot", label: "isSharedChargingDepot" },
    { key: "isCircuitEnabled", label: "isCircuitEnabled" },
    { key: "publishCDRsToCCDR", label: "publishCDRsToCCDR" },
    { key: "zuoraId", label: "Zuora ID" },
    { key: "countryCode", label: "Country Code" },
    { key: "state", label: "State" },
    { key: "city", label: "City" },
    { key: "streetAddress", label: "Street Address" },
    { key: "country", label: "Country" },
    {
      key: "fullyAutomatedChargingMode",
      label: "Fully Automated Mode Count",
    },
    { key: "simpleChargingMode", label: "Supervised Mode Count" },
    { key: "unmanagedChargingMode", label: "Unmanaged Mode Count" },
    { key: "chargerCount", label: "Charger Count" },
    { key: "portCount", label: "Port Count" },
  ];

  useEffect(() => {
    if (isUsersLoaded === false) {
      saveAllUsersGlobal();
    }
    getUserData();
  }, [isUsersLoaded]);

  const getUserData = () => {
    userEmailAndName(allUsers);
  };

  const userEmailAndName = (data) => {
    let allEmails = [];
    data?.map((single) => {
      allEmails.push(`${single.email}-${single.firstname} ${single.lastname}`);
    });
    setUsers(allEmails);
  };

  const getCount = async () => {
    setLoading(true);
    let res = await apiDepots.getDepotsChargingMode();
    if (res.statusCode >= 200 && res.statusCode <= 299) {
      setDepotsData(
        depotsData.map((itm) => ({
          ...itm,
          ...res.data.find((item) => itm.depotId === item?.depotId),
        }))
      );
      setOpenPopup({
        isOpen: true,
        title: "Export CSV",
        child: "exportCSV",
      });

      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getDepotInfo = async () => {
    setLoading(true);
    let activeDepotCounter = 0;
    let provisioningDepotCounter = 0;
    let timeZoneArray = [];
    await Promise.allSettled([
      getDepotDetailsByCompanyId(id),
      getBillingDepotsPlanDetails(id),
      getActiveDepots(),
    ]).then(async (allPromise) => {
      const res = allPromise[0].value;
      const res2 = allPromise[1].value;
      const activeDepotsresponse = allPromise[2].value;
      if (
        res?.statusCode === 200 &&
        res2?.statusCode === 200 &&
        activeDepotsresponse?.statusCode === 200
      ) {
        const data = res?.data;
        const data2 = res2?.data;
        const activeDepotsData = activeDepotsresponse?.data;
        data.depotsList = data.depotsList.map((itm) => {
          if (
            !timeZoneArray.includes(
              itm.depotDetails.depotInfo.address.timeZoneId
            )
          )
            timeZoneArray.push(itm.depotDetails.depotInfo.address.timeZoneId);
          if (itm.status === "running") activeDepotCounter += 1;
          else if (itm.status === "provisioning") provisioningDepotCounter += 1;
          return {
            ...(itm.isHomeChargingDepot === undefined
              ? (itm.isHomeChargingDepot = false)
              : itm.isHomeChargingDepot),
            ...(itm.isMinaEnrolledDepot === undefined
              ? (itm.isMinaEnrolledDepot = false)
              : itm.isMinaEnrolledDepot),
            ...data2.find((item) => item.depotId === itm.depotId && item),
            ...activeDepotsData.find(
              (item) => item.depotId == itm.depotId && item
            ),
            ...itm,
          };
        });

        setRequests([...data.depotsList]);
        setTimeZones(timeZoneArray);
        setDepotsForFilter([...data.depotsList]);
        setRequestsData([...data.depotsList]);
        setCompanyInfo({ ...data });
        setCount(data?.depotsList?.length);
        setActiveDepotCount(activeDepotCounter);
        setProvisioningDepotCount(provisioningDepotCounter);
        setLoading(false);
      }
    });
  };
  const handleSearch = (e) => {
    let target = e.target?.value?.trim();
    setFilterFn({
      fn: (items) => {
        if (target === "") return items;
        else
          return items.filter(
            (x) =>
              (x.depotName &&
                x.depotName.toLowerCase().includes(target.toLowerCase())) ||
              (x.apiUrl &&
                x.apiUrl.toLowerCase().includes(target.toLowerCase())) ||
              (x.depotId &&
                x.depotId.toLowerCase().includes(target.toLowerCase())) ||
              (x.status &&
                x.status.toLowerCase().includes(target.toLowerCase()))
          );
      },
    });
  };
  const isOneFieldSelected = (type) => {
    let bool = false;
    type.forEach((parent) => {
      parent.children.forEach((child) => {
        if (child.children) {
          child.children.forEach((grandChild) => {
            bool = grandChild.checked || bool;
          });
        } else {
          bool = child.checked || bool;
        }
      });
    });
    return bool;
  };

  const applyFilter = (
    tempAllDepotType,
    tempAllCountryCode,
    tempCloudProvider,
    tempAllDepotStatus,
    tempAllCdrFlag,
    tempAllSimulatedFlag,
    tempAllHomeChargingFlag,
    tempMinaEnrolledFlag,
    tempIsAdvenirEnrolledDepotFlag,
    tempIsRFIDCapable,
    tempAllSharedChargingFlag,
    tempPublishCDRsToCCDR,
    tempAllEnableCircuitsFlag,
    tempSelectedTimeZone,
    tempIsWorkplaceChargingDepotFlag,
    tempSendGetConfigurationFlag
  ) => {
    setIsFiltered(false);
    let counter = 0;
    let activeDepotCounter = 0;
    let provisioningDepotCounter = 0;
    setRequests(
      depotsForFilter.filter((depot) => {
        let flag = 1;
        if (isOneFieldSelected(tempAllSimulatedFlag)) {
          setIsFiltered(true);
          if (
            !(
              (tempAllSimulatedFlag[0].children[0].checked === true &&
                depot.isSimulated) ||
              (tempAllSimulatedFlag[0].children[1].checked === true &&
                depot.isSimulated === false)
            )
          )
            flag = 0;
        }
        if (isOneFieldSelected(tempAllSharedChargingFlag)) {
          setIsFiltered(true);
          if (
            !(
              (tempAllSharedChargingFlag[0].children[0].checked === true &&
                depot.isSharedChargingDepot) ||
              (tempAllSharedChargingFlag[0].children[1].checked === true &&
                depot.isSharedChargingDepot === false)
            )
          )
            flag = 0;
        }
        if (isOneFieldSelected(tempPublishCDRsToCCDR)) {
          setIsFiltered(true);
          if (
            !(
              (tempPublishCDRsToCCDR[0].children[0].checked === true &&
                depot.publishCDRsToCCDR) ||
              (tempPublishCDRsToCCDR[0].children[1].checked === true &&
                depot.publishCDRsToCCDR === false)
            )
          )
            flag = 0;
        }
        if (isOneFieldSelected(tempAllEnableCircuitsFlag)) {
          setIsFiltered(true);
          if (
            !(
              (tempAllEnableCircuitsFlag[0].children[0].checked === true &&
                depot.isCircuitEnabled) ||
              (tempAllEnableCircuitsFlag[0].children[1].checked === true &&
                depot.isCircuitEnabled === false)
            )
          )
            flag = 0;
        }
        if (isOneFieldSelected(tempAllCdrFlag)) {
          setIsFiltered(true);
          if (
            !(
              (tempAllCdrFlag[0].children[0].checked === true && depot.isCDR) ||
              (tempAllCdrFlag[0].children[1].checked === true &&
                depot.isCDR === false)
            )
          )
            flag = 0;
        }
        if (isOneFieldSelected(tempCloudProvider)) {
          setIsFiltered(true);
          if (
            !(
              (tempCloudProvider[0].children[0].checked === true &&
                (depot.cloudProvider === undefined ||
                  depot.cloudProvider === "AWS")) ||
              (tempCloudProvider[0].children[1].checked === true &&
                depot.cloudProvider === "GCP")
            )
          )
            flag = 0;
        }
        if (isOneFieldSelected(tempAllHomeChargingFlag)) {
          setIsFiltered(true);
          if (
            !(
              (tempAllHomeChargingFlag[0].children[0].checked === true &&
                depot.isHomeChargingDepot) ||
              (tempAllHomeChargingFlag[0].children[1].checked === true &&
                (depot.isHomeChargingDepot === false ||
                  depot.isHomeChargingDepot === undefined))
            )
          )
            flag = 0;
        }
        if (isOneFieldSelected(tempMinaEnrolledFlag)) {
          setIsFiltered(true);
          if (
            !(
              (tempMinaEnrolledFlag[0].children[0].checked === true &&
                depot.isMinaEnrolledDepot) ||
              (tempMinaEnrolledFlag[0].children[1].checked === true &&
                (depot.isMinaEnrolledDepot === false ||
                  depot.isMinaEnrolledDepot === undefined))
            )
          )
            flag = 0;
        }
        if (isOneFieldSelected(tempIsAdvenirEnrolledDepotFlag)) {
          setIsFiltered(true);
          if (
            !(
              (tempIsAdvenirEnrolledDepotFlag[0].children[0].checked === true &&
                depot.isAdvenirEnrolledDepot) ||
              (tempIsAdvenirEnrolledDepotFlag[0].children[1].checked === true &&
                (depot.isAdvenirEnrolledDepot === false ||
                  depot.isAdvenirEnrolledDepot === undefined))
            )
          )
            flag = 0;
        }
        if (isOneFieldSelected(tempIsWorkplaceChargingDepotFlag)) {
          setIsFiltered(true);
          if (
            !(
              (tempIsWorkplaceChargingDepotFlag[0].children[0].checked ===
                true &&
                depot.isWorkplaceChargingDepot) ||
              (tempIsWorkplaceChargingDepotFlag[0].children[1].checked ===
                true &&
                (depot.isWorkplaceChargingDepot === false ||
                  depot.isWorkplaceChargingDepot === undefined))
            )
          )
            flag = 0;
        }
        if (isOneFieldSelected(tempSendGetConfigurationFlag)) {
          setIsFiltered(true);
          if (
            !(
              (tempSendGetConfigurationFlag[0].children[0].checked === true &&
                depot.sendGetConfiguration) ||
              (tempSendGetConfigurationFlag[0].children[1].checked === true &&
                (depot.sendGetConfiguration === false ||
                  depot.sendGetConfiguration === undefined))
            )
          )
            flag = 0;
        }
        if (isOneFieldSelected(tempIsRFIDCapable)) {
          setIsFiltered(true);
          if (
            !(
              (tempIsRFIDCapable[0].children[0].checked === true &&
                depot.isRFIDDepot) ||
              (tempIsRFIDCapable[0].children[1].checked === true &&
                (depot.isRFIDDepot === false ||
                  depot.isRFIDDepot === undefined))
            )
          )
            flag = 0;
        }
        if (isOneFieldSelected(tempAllDepotType)) {
          setIsFiltered(true);
          tempAllDepotType[0].children.forEach((item) => {
            if (item.name === depot.depotType && !item.checked) flag = 0;
          });
        }
        if (isOneFieldSelected(tempAllCountryCode)) {
          setIsFiltered(true);
          tempAllCountryCode.forEach((parent) =>
            parent.children.forEach((children) => {
              children.children.forEach((grandChild) => {
                if (
                  grandChild.value ===
                    depot.depotDetails.depotInfo.address.countryCode &&
                  grandChild.checked === false
                )
                  flag = 0;
              });
            })
          );
        }
        if (isOneFieldSelected(tempAllDepotStatus)) {
          setIsFiltered(true);
          if (
            !(
              (tempAllDepotStatus[0].children[0].checked === true &&
                depot.activeStatus &&
                !depot.billingActivatedAt) ||
              (tempAllDepotStatus[0].children[1].checked === true &&
                depot.billingActivatedAt) ||
              (tempAllDepotStatus[0].children[2].checked === true &&
                !depot.activeStatus)
            )
          ) {
            flag = 0;
          }
        }
        if (tempSelectedTimeZone.length > 0) {
          setIsFiltered(true);
          if (
            !tempSelectedTimeZone.includes(
              depot.depotDetails.depotInfo.address.timeZoneId
            )
          )
            flag = 0;
        }
        setAllDepotsType([...tempAllDepotType]);
        setAllCdrFlag([...tempAllCdrFlag]);
        setAllSimulatedFlag([...tempAllSimulatedFlag]);
        setAllHomeChargingFlag([...tempAllHomeChargingFlag]);
        setMinaEnrolledFlag([...tempMinaEnrolledFlag]);
        setIsAdvenirEnrolledDepotFlag([...tempIsAdvenirEnrolledDepotFlag]);
        setIsWorkplaceChargingDepotFlag([...tempIsWorkplaceChargingDepotFlag]);
        setSendGetConfigurationFlag([...tempSendGetConfigurationFlag]);
        setCountryCode([...tempAllCountryCode]);
        setSelectedTimeZone([...tempSelectedTimeZone]);
        setCloudProvider([...tempCloudProvider]);
        setDepotStatus([...tempAllDepotStatus]);
        setIsRFIDCapable([...tempIsRFIDCapable]);
        setAllSharedChargingFlag([...tempAllSharedChargingFlag]);
        setPublishCDRsToCCDR([...tempPublishCDRsToCCDR]);
        setAllEnableCircuitsFlag([...tempAllEnableCircuitsFlag]);

        setFilterDrawerOC(false, "", {});
        if (flag == 0) return false;
        else {
          if (depot.status === "running") activeDepotCounter += 1;
          else if (depot.status === "provisioning")
            provisioningDepotCounter += 1;
          counter += 1;
          return true;
        }
      })
    );
    setCount(counter);
    setActiveDepotCount(activeDepotCounter);
    setProvisioningDepotCount(provisioningDepotCounter);
    setDepotsForFilter(Object.values(JSON.parse(JSON.stringify(requestsData))));
  };
  const toggleDrawer = (openClose, title, item) => {
    setDrawerOC({ isOpen: openClose, title: title, data: item });
  };
  const filterToggleDrawer = (openClose, title, item) => {
    setFilterDrawerOC({ isOpen: openClose, title: title, data: item });
  };

  const handleToast = React.useCallback(
    (params) => {
      setToast(params);
    },
    [toast]
  );

  const handleLoading = React.useCallback(
    (params) => {
      setLoading(params);
    },
    [isLoading]
  );

  const handleConfirmUpdateDataSync = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: "Update Data Sync?",
      subTitle:
        "This action will update the datasync with latest depot configurations.",
      type: "secondary",
      onConfirm: () => handleUpdateDataSync(id),
    });
  };

  const handleUpdateDataSync = (depot) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    setLoading(true);

    apiDepots
      .synchroniseDepots(depot)
      .then((result) => {
        setLoading(false);

        if (result.statusCode >= 200 && result.statusCode <= 299) {
          setToast({
            isOpen: true,
            message: "Success",
            type: "success",
          });
        } else {
          setToast({
            isOpen: true,
            message: "Updating data sync failed",
            type: "error",
          });
        }
      })
      .catch((error) => {
        setLoading(false);

        setToast({
          isOpen: true,
          message: "Updating data sync failed",
          type: "error",
        });
      });
  };

  return (
    <>
      {<Loader isLoading={isLoading} />}
      <OpsBreadcrumb AllBreadcrumbsLinks={AllBreadcrumbsLinks} title="Depots" />
      <PageHeader title={companyInfo.companyName || ""} />
      <div className="company_details">
        <Grid container>
          <Grid item xs={3}>
            <div className="single_details">
              <p className="title">Company Id</p>
              <Controls.CopyToClipboard
                name={
                  companyInfo.companyId === null ? "-" : companyInfo.companyId
                }
                setToast={setToast}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="single_details">
              <p className="title">Zuora Id</p>
              <Controls.CopyToClipboard
                name={companyInfo.zuoraId === null ? "-" : companyInfo.zuoraId}
                setToast={setToast}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="single_details">
              <p className="title">Billing Address</p>
              <Controls.CopyToClipboard
                name={
                  companyInfo && companyInfo.billingAddress
                    ? companyInfo.billingAddress.streetAddress
                    : "-"
                }
                setToast={setToast}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="single_details">
              <p className="title">Rate Plan</p>
              <Controls.CopyToClipboard
                name={
                  companyInfo.ratePlan === undefined
                    ? "-"
                    : companyInfo.ratePlan
                }
                setToast={setToast}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <PageMainContent>
        <ToolbarMemoised
          handleSearch={handleSearch}
          className="searchInput"
          filterToggleDrawer={filterToggleDrawer}
          isFiltered={isFiltered}
          count={count}
          activeDepotCount={activeDepotCount}
          provisioningDepotCount={provisioningDepotCount}
          setOpenPopup={setOpenPopup}
          getCount={getCount}
          isLoading={isLoading}
          data={result?.searchData}
        />
        <TableContainer>
          <TableHead />
          <React.Fragment>
            <TableBodyMemoised
              data={recordsAfterPagingAndSorting}
              toggleDrawer={toggleDrawer}
              setToast={handleToast}
              setLoading={handleLoading}
              componentPrivileges={componentPrivilege}
              handleConfirmUpdateDataSync={handleConfirmUpdateDataSync}
            />
          </React.Fragment>
        </TableContainer>
        {TablePagination}

        <CommonDrawer DrawerOC={DrawerOC} toggleDrawer={toggleDrawer}>
          {DrawerOC.title === "Depots Details" && (
            <CompanyDetails
              setToast={setToast}
              DrawerOC={DrawerOC}
              getDepotInfo={getDepotInfo}
              setDrawerOC={setDrawerOC}
              users={users}
            />
          )}
        </CommonDrawer>
        <NewCommonFilterDrawer
          DrawerOC={FilterDrawerOC}
          toggleDrawer={filterToggleDrawer}
        >
          {FilterDrawerOC.title === "FILTER" && (
            <NewDepotsFilter
              filterData={filterData}
              countryCode={countryCode}
              timeZones={timeZones}
              setFilterData={setFilterData}
              applyFilter={applyFilter}
              allDepotsType={allDepotsType}
              allCdrFlag={allCdrFlag}
              allSimulatedFlag={allSimulatedFlag}
              allHomeChargingFlag={allHomeChargingFlag}
              minaEnrolledFlag={minaEnrolledFlag}
              isAdvenirEnrolledDepotFlag={isAdvenirEnrolledDepotFlag}
              isRFIDCapable={isRFIDCapable}
              allSharedChargingFlag={allSharedChargingFlag}
              allEnableCircuitsFlag={allEnableCircuitsFlag}
              isWorkplaceChargingDepotFlag={isWorkplaceChargingDepotFlag}
              sendGetConfigurationFlag={sendGetConfigurationFlag}
              publishCDRsToCCDR={publishCDRsToCCDR}
              selectedTimeZone={selectedTimeZone}
              cloudProvider={cloudProvider}
              depotStatus={depotStatus}
            />
          )}
        </NewCommonFilterDrawer>

        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} minWidth="50%">
          {openPopup.child === "exportCSV" ? (
            <ExportCSV
              setOpenPopup={setOpenPopup}
              data={depotsData?.filter(
                (row) =>
                  result?.searchData?.find(
                    (item) => item.depotId === row.depotId
                  ) !== undefined
              )}
              allColumnsHeaders={allColumnsHeaders}
              headers={headers}
              setHeaders={setHeaders}
              fileName={
                isFiltered === true
                  ? `${
                      new Date().toISOString().slice(0, 10) +
                      " " +
                      companyInfo.companyName +
                      " overview - filtered.csv"
                    }`
                  : `${
                      new Date().toISOString().slice(0, 10) +
                      " " +
                      companyInfo.companyName +
                      " overview.csv"
                    }`
              }
            />
          ) : null}
        </Popup>
        <ToastMessage toast={toast} setToast={setToast} />
      </PageMainContent>
    </>
  );
};

export default CompanyDepotDetails;
