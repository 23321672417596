import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  InputAdornment,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Grid,
  Tooltip,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Checkbox,
  InputBase,
  Badge,
  Zoom,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { isEqual } from "lodash";
import InfoIcon from "@mui/icons-material/Info";
import DoneIcon from "@mui/icons-material/Done";
import SearchIcon from "@mui/icons-material/Search";
import Controls from "../../../../components/controls/Controls";
import useTable from "../../../../components/UseTable";
import { titleLabels } from "../../../../constants/TitleLabels";
import LaunchIcon from "@mui/icons-material/Launch";
import ViewAgendaOutlinedIcon from "@mui/icons-material/ViewAgendaOutlined";
import TuneIcon from "@mui/icons-material/Tune";
import { formatDistance, format, secondsToMinutes } from "date-fns";
import CommonFilterDrawer from "../../../../components/SideFilter";
import FaultsTroubleShoot from "./FaultsTroubleShoot";
import no_Data from "./../../../../assets/images/png/Vector.png";
import "./../../ChargerHealth.scss";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import sub from "date-fns/sub";
import add from "date-fns/add";
import { updateFaultStatus } from "../../ChargerHealthService";
import { getData } from "../../../../utils/Storage";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../../../components/Loader";
import EditIcon from "@mui/icons-material/Edit";
import { updateNotes, bulkUpdateNotes } from "../../ChargerHealthService";
import Popup from "../../../../components/Popup";
import ExportCSV from "../../../../components/ExportCSV";

const useStyles = makeStyles((theme) => ({
  searchInput: {
    paddingRight: "10px",
    width: "30%",
    ["@media (max-width:1023px)"]: {
      width: "40%",
    },
  },
}));

const headings = [
  {
    key: "accountName",
    id: "accountName",
    label: "Account",
    styles: {
      position: "sticky",
      zIndex: 800,
      left: "74px",
      boxShadow: "2px 0px 7px rgba(0, 0, 0, 0.0867024)",
    },
  },
  { key: "depotName", id: "depotName", label: "Depot" },
  {
    key: "vendorManufacturer",
    id: "vendorManufacturer",
    label: "Manufacturer (OEM)",
  },
  { key: "vendorModel", id: "vendorModel", label: "Model (OEM)" },
  { key: "make", id: "make", label: "Sold as manufacturer" },
  { key: "model", id: "model", label: "Sold as model" },
  { key: "chargePortId", id: "chargePortId", label: "Charge PortID" },
  { key: "errorCode", id: "errorCode", label: "Fault Code" },
  { key: "vendorErrorCode", id: "vendorErrorCode", label: "Vendor Error Code" },
  { key: "faultNumber", id: "faultNumber", label: "Fault Number" },
  { key: "title", id: "title", label: "Fault Title" },
  {
    key: "severity",
    id: "severity",
    label: (
      <div className="rfidStatus">
        <p>Fault Impact</p>
        <Tooltip
          arrow
          TransitionComponent={Zoom}
          title=<ul>
            <li>
              <b>High:</b> A fault that prevents charging. If the issue has
              cleared, charging may become functional again
            </li>
            <li>
              <b>Medium:</b> A fault that negatively impacts, but does not
              prevent charging (e.g. charging power reduced due to low or high
              temparatures)
            </li>
            <li>
              <b>Low:</b> A fault that directly does not impact charging but
              informs about a change to the charger
            </li>
            <li>
              <b>Diagnostic:</b> A fault that is not displayed in the customer
              UI or fault alert messages regardless of user settings
            </li>
            <li>
              <b>Unknown:</b> A fault that is displayed to customers although
              severity level has not been confirmed
            </li>
          </ul>
        >
          <InfoIcon fontSize="small" className="rfidStatusInfoIcon" />
        </Tooltip>
      </div>
    ),
    csvLabel: "Fault Impact",
  },
  {
    key: "chargerStatusIndicator",
    id: "chargerStatusIndicator",
    label: "Expected Status Indicator",
  },
  { key: "createdAt", id: "createdAt", label: "Open duration", type: "date" },
  { key: "status", id: "status", label: "Status" },
  { key: "modifiedBy", id: "modifiedBy", label: "Last modified by" },
  {
    key: "latest_time",
    id: "latest_time",
    label: "Last modified at",
    type: "date",
  },
  {
    disableSorting: true,
    key: "notes",
    id: "notes",
    label: "Notes/Ticket details",
  },
];

const headCells = (
  setSelected,
  recordsAfterPagingAndSorting,
  setRecordsAfterPagingAndSorting
) => {
  const hc = [
    {
      disableSorting: true,
      id: "some",
      label: (
        <Checkbox
          checked={
            recordsAfterPagingAndSorting.length > 0 &&
            recordsAfterPagingAndSorting?.every((row) => row.selected)
          }
          onChange={(e) => {
            const updatedRecords = recordsAfterPagingAndSorting?.map((row) => ({
              ...row,
              selected: e.target.checked,
            }));
            setSelected(e.target.checked ? updatedRecords : []);
            setRecordsAfterPagingAndSorting(updatedRecords);
          }}
        />
      ),
      styles: {
        position: "sticky",
        zIndex: 801,
        left: 0,
      },
    },
    ...headings,
    { disableSorting: true, key: "action", id: "action", label: "Action" },
  ];
  return hc;
};

const Rows = React.memo((props) => {
  const {
    item,
    toggleDrawerTCG,
    handleSingleClick,
    handleStatusChange,
    sendNotes,
    componentPrivilege,
  } = props;
  function toTimestamp(strDate) {
    let datum = Date.parse(strDate);
    return datum / 1000;
  }
  const [notes, setNotes] = React.useState(item.notes ? item.notes : "");
  const [originalNotes, setOriginalNotes] = React.useState(
    item.notes ? item.notes : ""
  );
  const handleNotesChange = (event) => {
    if (event.target.value !== " ") setNotes(event.target.value);
  };

  const [editable, setEditable] = React.useState(true);
  const classes = useStyles();

  const fetchOpenDurationTime = (fault) => {
    let openDuration = titleLabels.NOT_AVAILABLE;
    if (fault.status === "Closed" && fault.createdAt !== undefined) {
      openDuration = (
        <>
          <p className="openDurationDist">
            {formatDistance(
              new Date(new Date(`${item.createdAt}`)),
              new Date(new Date(`${item.latest_time}`)),
              { addSuffix: true }
            ) || titleLabels.NOT_AVAILABLE}
          </p>
          <p className="openDurationDate">
            {new Date(format(new Date(`${item.createdAt}`), "PPpp"))
              .toUTCString()
              .slice(0, -7) + "   UTC"}
          </p>
        </>
      );
    } else if (fault.createdAt !== undefined) {
      openDuration = (
        <>
          <p className="openDurationDist">
            {formatDistance(
              new Date(new Date(`${item.createdAt}`)),
              new Date(),
              { addSuffix: true }
            ) || titleLabels.NOT_AVAILABLE}
          </p>
          <p className="openDurationDate">
            {new Date(format(new Date(`${item.createdAt}`), "PPpp"))
              .toUTCString()
              .slice(0, -7) + "   UTC"}
          </p>
        </>
      );
    }
    return openDuration;
  };
  return (
    <>
      <TableRow hover={true} className="opentable" id="openFaultsTable">
        <TableCell align="center" className="faultsCheckboxCell">
          <Checkbox
            onClick={(e) => handleSingleClick(e, item)}
            checked={item?.selected === true}
          />
        </TableCell>
        <TableCell align="left" className="faultsAccountCell">
          {item.accountName || titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell>{item.depotName || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>
          {item.vendorManufacturer || titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell>{item.vendorModel || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>{item.make || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>{item.model || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>
          <Tooltip title={item.chargePortId || titleLabels.NOT_AVAILABLE}>
            <p className="table_col_ellipsis">
              {item.chargePortId || titleLabels.NOT_AVAILABLE}
            </p>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={item.errorCode || titleLabels.NOT_AVAILABLE}>
            <p className="table_col_ellipsis">
              {item.errorCode || titleLabels.NOT_AVAILABLE}
            </p>
          </Tooltip>
        </TableCell>
        <TableCell>
          {item.vendorErrorCode || titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell>{item.faultNumber || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>
          <Tooltip title={item.title || titleLabels.NOT_AVAILABLE}>
            <p className="table_col_ellipsis">
              {item.title || titleLabels.NOT_AVAILABLE}
            </p>
          </Tooltip>
        </TableCell>
        <TableCell>{item.severity || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>
          {item.chargerStatusIndicator || titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell>{fetchOpenDurationTime(item)}</TableCell>
        <TableCell align="center">
          {item.status ? (
            <>
              {componentPrivilege?.includes("Update Fault Status") ? (
                <FormControl
                  className="faultStatusCell"
                  variant="outlined"
                  size="small"
                >
                  <Select
                    value={item.status ? item.status : null}
                    onChange={(e) => handleStatusChange(e, item)}
                  >
                    <MenuItem value="Open">Open</MenuItem>
                    <MenuItem value="Closed">Close</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                item.status
              )}
            </>
          ) : (
            "No Status"
          )}
        </TableCell>
        <TableCell>
          {item.updatedBy
            ? item.updatedBy
            : item.modifiedBy || titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell>
          {(
            <>
              {/* {" "}
              {format(
                new Date(`${item.latest_time}`),
                "dd-MMM-yyyy, h.mm a"
              )}{" "}
              UTC{" "} */}
              {new Date(format(new Date(`${item.latest_time}`), "PPpp"))
                .toUTCString()
                .slice(0, -7) + "   UTC"}
            </>
          ) || titleLabels.NOT_AVAILABLE}
        </TableCell>

        <TableCell
          className="notesCell"
          style={{ border: !editable ? "1px solid #0064D4" : "" }}
        >
          <div className="container">
            {item.status && notes && notes.includes("https") && editable ? (
              <Tooltip title={notes ? notes : ""}>
                <a href={notes} target="_blank" className="link">
                  {notes.slice(-20)}
                </a>
              </Tooltip>
            ) : item.status ? (
              <FormControl>
                <Tooltip title={notes ? notes : ""}>
                  <InputBase
                    placeholder="Add a note/ticket link"
                    readOnly={editable}
                    size="medium"
                    value={notes}
                    onChange={handleNotesChange}
                    className="notesInputBase"
                  />
                </Tooltip>
              </FormControl>
            ) : (
              "No Status"
            )}
            {!editable && item.status && (
              <Controls.ActionButton
                onClick={() => {
                  sendNotes(item, notes);
                  setOriginalNotes(notes);
                  setEditable(true);
                }}
              >
                <Tooltip title={"Save"}>
                  <DoneIcon color="primary" className="doneIcon" />
                </Tooltip>
              </Controls.ActionButton>
            )}
            {!editable && item.status && (
              <Controls.ActionButton
                onClick={() => {
                  setNotes(originalNotes);
                  setEditable(true);
                }}
              >
                <Tooltip title="Discard">
                  <CloseIcon color="error" />
                </Tooltip>
              </Controls.ActionButton>
            )}
            {editable &&
              item.status &&
              componentPrivilege?.includes("Faults - Edit Notes Details") && (
                <Controls.ActionButton
                  onClick={() => {
                    setEditable(false);
                  }}
                >
                  <Tooltip title="Edit">
                    <EditIcon color="primary" fontSize="small" />
                  </Tooltip>
                </Controls.ActionButton>
              )}
          </div>
        </TableCell>

        <TableCell className="dataDogCell">
          <div className="flexDisplay">
            <Controls.ActionButton
              color="primary"
              onClick={() => {
                window.open(
                  `https://app.datadoghq.com/logs?query=depot_id%3A${
                    item.depotId
                  }%20service%3Acentral-server%20%40chargeport_id%3A${
                    item.chargePortId
                  }&cols=host%2Cservice&index=&messageDisplay=inline&stream_sort=desc&from_ts=${new Date(
                    sub(new Date(`${item.latest_time}`), {
                      minutes: 2,
                    }).toISOString()
                  ).getTime()}&to_ts=${new Date(
                    add(new Date(`${item.latest_time}`), {
                      minutes: 2,
                    }).toISOString()
                  ).getTime()}&live=false`,
                  "_blank"
                );
              }}
            >
              <Tooltip title="Link to DataDog">
                <LaunchIcon fontSize="small" />
              </Tooltip>
            </Controls.ActionButton>
            <Controls.ActionButton
              color="primary"
              onClick={() => {
                toggleDrawerTCG(true, "Troubleshooting Guide", item);
              }}
            >
              <Tooltip title="Troubleshooting guide">
                <ViewAgendaOutlinedIcon fontSize="small" />
              </Tooltip>
            </Controls.ActionButton>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
});

const ToolbarMemoised = React.memo(
  ({
    handleSearch,
    className,
    toggleDrawer,
    setOpenPopup,
    isFiltered,
    selected,
    handleMultiStatusChange,
    faults,
    data,
    selectAllFaults,
    clearSelectAllFaults,
    recordsAfterPagingAndSorting,
    componentPrivilege,
  }) => {
    return (
      <Toolbar className="table_toolbar">
        <Controls.Input
          label={"Search"}
          className={className}
          inputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        {selected.length > 0 && selected.length === faults.length && (
          <p className="selected_Check_box_string">
            All {selected.length} faults are selected
          </p>
        )}
        {selected.length > 0 &&
          selected.length === recordsAfterPagingAndSorting.length &&
          selected.length !== faults.length && (
            <p className="selected_Check_box_string">
              All {selected.length} faults on this <br /> page are selected
            </p>
          )}
        {selected.length > 1 &&
          selected.length !== faults.length &&
          selected.length !== recordsAfterPagingAndSorting.length && (
            <p className="selected_Check_box_string">
              <span>{selected.length}</span> faults are selected
            </p>
          )}
        {faults.length !== selected.length && selected.length > 0 && (
          <Controls.ActionButton onClick={selectAllFaults}>
            <p className="selected_Check_box_string" id="colorCodeString">
              Select all {faults.length} faults
            </p>
          </Controls.ActionButton>
        )}
        {faults.length === selected.length && selected.length > 0 && (
          <Controls.ActionButton onClick={clearSelectAllFaults}>
            <p className="selected_Check_box_string" id="colorCodeString">
              Clear selection
            </p>
          </Controls.ActionButton>
        )}
        {componentPrivilege?.includes("Update Fault Status") && (
          <>
            {selected.length > 1 && (
              <FormControl
                variant="outlined"
                size="small"
                className="multiStatusSelect"
              >
                <InputLabel id="demo-simple-select-filled-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  label="Status"
                  onChange={(e) => handleMultiStatusChange(e)}
                >
                  <MenuItem value="Open">Open</MenuItem>
                  <MenuItem value="Closed">Close</MenuItem>
                </Select>
              </FormControl>
            )}
          </>
        )}
        <Grid sm item />
        <Controls.Button
          text="Export CSV"
          variant="outlined"
          disabled={data.length === 0}
          id="offlinePortsExportCSV"
          onClick={() => {
            setOpenPopup({
              isOpen: true,
              title: "Export CSV",
              child: "exportCSV",
            });
          }}
        />
        <Badge color="primary" variant="dot" invisible={!isFiltered}>
          <Controls.Button
            text="Filter"
            variant="outlined"
            startIcon={
              <TuneIcon style={{ color: isFiltered ? "#2770D8" : "#A3B6C7" }} />
            }
            onClick={() => {
              toggleDrawer(true, "FILTER", {});
            }}
            className="filter"
            style={{
              border: isFiltered ? "1px solid #2770D8" : "1px solid #A3B6C7",
            }}
          />
        </Badge>
      </Toolbar>
    );
  }
);

const RowsMemoised = React.memo((props) => {
  return <Rows {...props} />;
});

const TableBodyMemoised = React.memo(
  ({
    data,
    callAPI,
    toggleDrawerTCG,
    handleSingleClick,
    handleStatusChange,
    sendNotes,
    componentPrivilege,
  }) => {
    return (
      <TableBody>
        {data.map((item, index) => (
          <RowsMemoised
            item={item}
            key={`${item.email}_${index}`}
            callAPI={callAPI}
            toggleDrawerTCG={toggleDrawerTCG}
            handleSingleClick={handleSingleClick}
            handleStatusChange={handleStatusChange}
            sendNotes={sendNotes}
            componentPrivilege={componentPrivilege}
          />
        ))}
      </TableBody>
    );
  },
  function (prevProps, nextProps) {
    if (isEqual(prevProps.data, nextProps.data)) {
      return true;
    }
    return false;
  }
);

const FaultsTable = (props) => {
  const {
    toggleDrawer,
    faults,
    setFaults,
    faultsImpact,
    openDurationFilter,
    accountsFilter,
    makeModelFilter,
    troubleShootData,
    selectedFilter,
    isFiltered,
    // getOpenFaultsWithOutDepots,
    getOpenFaultsWithDepots,
    setToast,
    setToastSecond,
  } = props;
  const classes = useStyles();
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    type: "primary",
  });
  const [openPopup, setOpenPopup] = React.useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });
  const [headers, setHeaders] = useState(
    headings?.map((row) => ({
      ...row,
      name: row.csvLabel || row.label,
      checked: false,
    }))
  );
  const componentPrivilege = useSelector(
    (state) => state?.user?.componentPrivilege?.chargerHealth
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [filterFn, setFilterFn] = React.useState({ fn: (items) => items });
  const [searchData, setSearchData] = React.useState([]);
  const [TableContainer, setTableContainer] = React.useState("table");
  const [TableHead, setTableHead] = React.useState("thead");
  const [TablePagination, setTablePagination] = React.useState(() => <></>);
  const [recordsAfterPagingAndSorting, setRecordsAfterPagingAndSorting] =
    React.useState([]);
  const [notes, setNotes] = React.useState();

  const handleSingleClick = (e, item) => {
    const updatedRecords = [...recordsAfterPagingAndSorting];
    const selectedIndex = recordsAfterPagingAndSorting.indexOf(item);
    updatedRecords[selectedIndex] = {
      ...updatedRecords[selectedIndex],
      selected: !updatedRecords[selectedIndex]?.selected,
    };
    setRecordsAfterPagingAndSorting(updatedRecords);
    setSelected(
      e.target.checked
        ? updatedRecords?.filter((row) => row.selected)
        : selected?.filter((row) => row.faultId !== item.faultId)
    );
  };

  const sendNotes = (item, notes) => {
    let payload = {
      faultId: item.faultId,
      status: item.status,
      notes: notes,
    };
    updateNotes(item.faultId, payload);
  };

  const handleStatusChange = (event, item) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: "Change status",
      subTitle: "Are you sure you want to save the changes of this fault?",
      type: "secondary",
      onConfirm: () => {
        singleStatusChange(item, event);
      },
    });
  };

  const handleMultiStatusChange = (event) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: "Change status",
      subTitle:
        "Are you sure you want to save the changes of the selected faults?",
      type: "secondary",
      onConfirm: () => {
        multiStatusChange(event);
      },
    });
  };

  const multiStatusChange = async (event) => {
    setIsLoading(true);
    const selectedDepotIds = [
      ...new Set(selected.map((value) => value.depotId)),
    ];
    const payload = {
      status: event.target.value,
      user: `${getData("user_first_name")} ${getData("user_last_name")}`,
      depotFaultsDetails: [],
    };
    selectedDepotIds.map((single) => {
      const tempObj = {
        depotId: single,
        depotApiUrl: selected.find((o) => o.depotId === single).apiUrl,
        ids: selected
          .filter((a) => a.depotId === single)
          .map((value) => value.faultId),
      };
      payload.depotFaultsDetails.push(tempObj);
    });

    const res = await updateFaultStatus(payload);
    if (res?.statusCode >= 200 && res?.statusCode <= 299) {
      let fulfilled = [];
      let rejected = 0;
      res?.data?.result?.map((single) => {
        if (single?.status_code == 204) {
          fulfilled.push(
            ...payload.depotFaultsDetails.find(
              (a) => a.depotId == single.depotId
            ).ids
          );
        } else {
          rejected =
            rejected +
            payload.depotFaultsDetails.find(
              (a) => a.depotId == single.reason.depotId
            ).ids.length;
        }
      });
      if (fulfilled.length !== 0) {
        setToast({
          isOpen: true,
          message: `${fulfilled.length} Fault Status Changed Successfully `,
          type: "success",
        });
        const notesPayload = fulfilled?.map((row) => ({
          faultId: row,
          status: event.target.value,
        }));
        await bulkUpdateNotes(notesPayload);
      }
      if (rejected !== 0) {
        setToastSecond({
          isOpen: true,
          message: `${rejected} Fault Status did not Changed `,
          type: "error",
        });
      }
      getOpenFaultsWithDepots(selectedFilter);
    }
    setIsLoading(false);
    setConfirmDialog({ ...confirmDialog, isOpen: false });
  };

  const singleStatusChange = async (item, event) => {
    setIsLoading(true);
    const payload = {
      status: event.target.value,
      user: `${getData("user_first_name")} ${getData("user_last_name")}`,
      depotFaultsDetails: [
        {
          depotId: item.depotId,
          depotApiUrl: item.apiUrl,
          ids: [item.faultId],
        },
      ],
    };
    const res = await updateFaultStatus(payload);
    if (res?.statusCode >= 200 && res?.statusCode <= 299) {
      if (res?.data?.result[0]?.status_code == 204) {
        setToast({
          isOpen: true,
          message: "Fault Status Changed Successfully ",
          type: "success",
        });
        const notesPayload = {
          faultId: item.faultId,
          status: event.target.value,
          notes: item.notes,
        };
        await updateNotes(item?.faultId, notesPayload);
        getOpenFaultsWithDepots(selectedFilter);
      } else {
        setToast({
          isOpen: true,
          message: "Faults Status did not Changed",
          type: "error",
        });
      }
    } else {
      setToast({
        isOpen: true,
        message: "Sorry SomeThing Went Wrong",
        type: "error",
      });
    }
    setIsLoading(false);
    setConfirmDialog({ ...confirmDialog, isOpen: false });
  };

  const selectAllFaults = () => {
    const updatedFaults = faults?.map((row) => ({ ...row, selected: true }));
    setFaults(updatedFaults);
    setSelected(updatedFaults);
  };

  const clearSelectAllFaults = () => {
    setFaults(faults?.map((row) => ({ ...row, selected: false })));
    setSelected([]);
  };

  const [tableHeadCells, setTableheadCells] = React.useState(
    headCells(
      setSelected,
      recordsAfterPagingAndSorting,
      setRecordsAfterPagingAndSorting
    )
  );
  const result = useTable(faults, tableHeadCells, filterFn, true);

  React.useEffect(() => {
    setTableContainer(() => result.tableContainer);
  }, [result.tableContainer]);

  React.useEffect(() => {
    setTableHead(() => result.tableHead);
  }, [result.tableHead]);

  React.useEffect(() => {
    setTablePagination(result.tablePagination);
  }, [result.tablePagination]);

  React.useEffect(() => {
    if (selected.length !== faults.length) {
      setRecordsAfterPagingAndSorting(
        result.recordsAfterPagingAndSorting?.map((row) => ({
          ...row,
          selected: false,
        }))
      );
      setSelected([]);
    } else {
      setRecordsAfterPagingAndSorting(result.recordsAfterPagingAndSorting);
    }
  }, [result.recordsAfterPagingAndSorting]);

  React.useEffect(() => {
    setTableheadCells(
      headCells(
        setSelected,
        recordsAfterPagingAndSorting,
        setRecordsAfterPagingAndSorting
      )
    );
  }, [recordsAfterPagingAndSorting]);

  const handleSearch = (e) => {
    setFilterFn({
      fn: (items) => {
        if (e.target?.value?.trim() === "") return items;
        else
          return items.filter((row) =>
            Object.values(row)?.some((val) =>
              val
                ?.toString()
                ?.toLowerCase()
                ?.includes(e.target.value?.trim().toLowerCase())
            )
          );
      },
    });
  };
  const [DrawerTCG, setDrawerTCG] = React.useState({
    isOpen: false,
    tilte: "",
    data: {},
  });
  const toggleDrawerTCG = (openClose, title, item) => {
    setDrawerTCG({ isOpen: openClose, title: title, data: item });
  };

  return (
    <>
      <ToolbarMemoised
        handleSearch={handleSearch}
        className={classes.searchInput}
        toggleDrawer={toggleDrawer}
        setOpenPopup={setOpenPopup}
        isFiltered={isFiltered}
        selected={selected}
        handleMultiStatusChange={handleMultiStatusChange}
        faults={faults}
        data={result?.searchData}
        selectAllFaults={selectAllFaults}
        clearSelectAllFaults={clearSelectAllFaults}
        recordsAfterPagingAndSorting={recordsAfterPagingAndSorting}
        componentPrivilege={componentPrivilege}
      />
      <TableContainer>
        <TableHead />
        <React.Fragment>
          <TableBodyMemoised
            data={recordsAfterPagingAndSorting}
            toggleDrawerTCG={toggleDrawerTCG}
            handleSingleClick={handleSingleClick}
            handleStatusChange={handleStatusChange}
            sendNotes={sendNotes}
            componentPrivilege={componentPrivilege}
          />
        </React.Fragment>
      </TableContainer>
      {recordsAfterPagingAndSorting.length === 0 && (
        <div className="no_data_in_table">
          <div className="img_title">
            <img src={no_Data} alt="No data available" />
            <p className="no_data_title">No data available</p>
          </div>
        </div>
      )}
      {TablePagination}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      <Loader isLoading={isLoading} />
      <CommonFilterDrawer DrawerOC={DrawerTCG} toggleDrawer={toggleDrawerTCG}>
        <FaultsTroubleShoot
          troubleShootData={troubleShootData}
          DrawerTCG={DrawerTCG}
        />
      </CommonFilterDrawer>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        {openPopup.child === "exportCSV" ? (
          <ExportCSV
            setOpenPopup={setOpenPopup}
            data={result?.searchData?.map((row) => ({
              ...row,
              createdAt: row.createdAt
                ? format(new Date(row.createdAt), "dd-MM-yyyy hh:mm:ss")
                : titleLabels.NOT_AVAILABLE,
              latest_time: format(
                new Date(row.latest_time),
                "dd-MM-yyyy hh:mm:ss"
              ),
            }))}
            allColumnsHeaders={headings?.map((item) => ({
              ...item,
              label: item.csvLabel || item.label,
            }))}
            headers={headers}
            setHeaders={setHeaders}
            fileName={
              Object.values(selectedFilter).filter((e) => e.length).length !== 0
                ? `${
                    new Date().toISOString().slice(0, 10) +
                    "FPC open faults overview - filtered.csv"
                  }`
                : `${
                    new Date().toISOString().slice(0, 10) +
                    "FPC open faults overview.csv"
                  }`
            }
          />
        ) : null}
      </Popup>
    </>
  );
};

export default FaultsTable;
